import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import './index.css';

import LoginApp from './LoginApp';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  //<React.StrictMode>
  
  <BrowserRouter>   
    <LoginApp />
  </BrowserRouter>   
  
  //</React.StrictMode>
);

//serviceWorker.unregister();