import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import OlView from 'ol/View';
import OlFeature from 'ol/Feature';
import OlGeolocation from 'ol/Geolocation';
import { Geometry } from "ol/geom";
import Point from 'ol/geom/Point';
import OlCircle from 'ol/geom/Circle';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import 'antd/dist/reset.css';

import { AimOutlined  } from '@ant-design/icons';

//import { env } from './Env';
import SimpleButton from "./UI/SimpleButton"
import MapContext from "../Map/MapContext";
import { geolocationLayer } from '../Layers/Geolocation';
import { ObjectEvent } from "ol/Object";
import { Coordinate } from 'ol/coordinate';


interface geolocationProps {
    style: React.CSSProperties;
};

const Geolocation:React.FC<geolocationProps> = (props) => {  

    const setlog = false;
    setlog && console.log("Geolocation:React.FC");
    const map = useContext(MapContext);

    const [geolocationOpen, setGeolocationOpen] = useState<boolean>(false);
    const [geolocationFollow, setGeolocationFollow] = useState<boolean>(false);

    const [geolocationCoordinate, setGeolocationCoordinate] = useState<Coordinate>();
    const [geolocationAccuracy, setGeolocationAccuracy] = useState<number|undefined>(undefined);

    const geolocation = useMemo(() => {
        return new OlGeolocation({
            trackingOptions: {
             enableHighAccuracy: true,
            },
            projection: map?.getView().getProjection(),
        });
    },[map]);

    const positionFeature:OlFeature<Geometry> = useMemo(() => {
        return new OlFeature()
    },[]);

    const accuracyFeature:OlFeature<Geometry> = useMemo(() => {
        return new OlFeature()
    },[]);

    //const positionFeature:OlFeature<Geometry> = new OlFeature();
    //const accuracyFeature:OlFeature<Geometry> = new OlFeature();

    const positionStyle = new Style({
        image: new CircleStyle({
            radius: 6,
            fill: new Fill({
                color: '#3399CC',
            }),
            stroke: new Stroke({
                color: '#fff',
                width: 2,
            }),
        }),
    });

    const accuracyStyle = new Style({
        fill: new Fill({color: 'rgba(255, 255, 255, 0.1)'}),
        stroke: new Stroke({
            color: 'rgba(0,0,255,0.6)', 
            width: 1,
        }),
    });    

    accuracyFeature.setStyle(accuracyStyle);
    positionFeature.setStyle(positionStyle);
    
    const geolocationEvent = useCallback ( (evt: ObjectEvent)  => {
        setlog && console.log('Geolocation:React.FC useCallback.geolocationEvent.[geolocation]');
        setGeolocationCoordinate(geolocation.getPosition());
        setGeolocationAccuracy(geolocation.getAccuracy());
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    },[geolocation]);        

    useEffect(() => {
        setlog && console.log('Geolocation:React.FC useEffect.[map,geolocationOpen,geolocationFollow,geolocationCoordinate,geolocationAccuracy,positionFeature,accuracyFeature]');
       
        let geolocationSource = geolocationLayer.getSource();
        geolocationSource?.clear();

        if (geolocationCoordinate) {
            if (geolocationAccuracy) {
                accuracyFeature.setGeometry(new OlCircle(geolocationCoordinate, geolocationAccuracy));
            }
            positionFeature.setGeometry(new Point(geolocationCoordinate));
        }

        geolocationSource?.addFeature(accuracyFeature);
        geolocationSource?.addFeature(positionFeature);

        //setlog && console.log('useEffect.geolocation',geolocationOpen, geolocationFollow);

        if ( geolocationFollow ){
            const view: OlView | undefined = map?.getView();
            view?.animate({
                center: geolocationCoordinate,
                duration: 200
            });
        }

        // map entfernt
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[geolocationOpen,geolocationFollow,geolocationCoordinate,geolocationAccuracy,positionFeature,accuracyFeature]);

    function startGPS() {
        setlog && console.log('startGPS');
        
        if ( !geolocationOpen){
            setlog && console.log('startGPS Open');
            map?.addLayer(geolocationLayer);

            geolocation.setTracking(true);
            setGeolocationOpen(true);
            setGeolocationFollow(true);
            geolocation.on('change:position', geolocationEvent);
        }
        else{
            if (geolocationFollow) {
                setlog && console.log('startGPS Follow');
                setGeolocationFollow(false);
            }
            else{
                let geolocationSource = geolocationLayer.getSource();
                if ( geolocationSource ){
                    geolocation.un('change:position', geolocationEvent);
                    geolocationSource.clear();
                    map?.removeLayer(geolocationLayer);
                    setGeolocationOpen(false);
                    setlog && console.log('startGPS stopped');
                }
            }
        }
    }   

    return (
        <div>            
            <SimpleButton
                type="default"
                style={props.style} 
                shape="circle"
                onClick={startGPS}
                tooltip="GPS (Ein / Nicht verfolgen / Aus)"
                tooltipPlacement="right"
                icon={<AimOutlined />}
            />            
        </div>
    );
};

export {Geolocation};
