//Layer
import OlVectorLayer from 'ol/layer/Vector';
import OlSourceVector  from 'ol/source/Vector';
     
const geolocationLayer = new OlVectorLayer({
    source: new OlSourceVector(),
});

geolocationLayer.set('name', '_Geolocation');
geolocationLayer.set('label', 'geolocation');
geolocationLayer.set('digi', false);
geolocationLayer.set('select', false);

export {geolocationLayer};