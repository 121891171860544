import React from "react";
import 'antd/dist/reset.css';

import { LogoutOutlined  } from '@ant-design/icons';

import SimpleButton from "./UI/SimpleButton"
import { useAuth0 } from '@auth0/auth0-react';

interface logoutProps {
    style: React.CSSProperties;
};

const Logout:React.FC<logoutProps> = (props) => {  

    const {
        isAuthenticated,
        logout
    } = useAuth0(); 	

    const logoutClick = () =>{       
        console.log('logout');
        logout({ logoutParams: { returnTo: window.location.href } });
    };   

    if (isAuthenticated) {
        return (
            <div>            
                <SimpleButton
                    type="default"
                    style={props.style} 
                    shape="circle"
                    onClick={logoutClick}
                    tooltip="hasta la vista ..."
                    tooltipPlacement="right"
                    icon={<LogoutOutlined />}
                />            
            </div>
        );
    }
    else{
        return (
            <div>            
            </div>
        );       
    }
};

export {Logout};
