import React from 'react';
import { Button, Tooltip } from 'antd';
import { TooltipPlacement, AbstractTooltipProps } from 'antd/lib/tooltip';
import { ButtonProps } from 'antd/lib/button';

import  './SimpleButton.css';

interface DefaultProps {
    type: 'default' | 'primary' | 'ghost' | 'dashed' | 'danger' | 'link';
    tooltipProps: AbstractTooltipProps;
};

interface BaseProps {
    className?: string;
    icon?: React.ReactNode;
    iconName?: string;
    tooltip?: string;
    tooltipPlacement?: TooltipPlacement;
}

type SimpleButtonProps = BaseProps & Partial<DefaultProps> & ButtonProps;
 
const SimpleButton:React.FC<SimpleButtonProps> = (props) => {
    //console.log(props);
    return (
        <Tooltip 
            placement={props.tooltipPlacement} 
            title={props.tooltip}>
            <Button
                type={props.type || 'default'} 
                style={props.style}
                onClick={props.onClick}
                shape={props.shape}
                icon={props.icon}
            >
                {props.children}
            </Button>
        </Tooltip>
    );
};

export default SimpleButton;